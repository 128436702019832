import React from 'react';
import PropTypes from 'prop-types';

import { selectFirebase } from '../services/firebase/selectors';
import Container from '../components/Container';
import Button from '../components/Button';

class LoginPage extends React.PureComponent {
  state = {}

  login = () => {
    const { firebase } = this.props;
    firebase.login({ provider: 'google', type: 'popup' }).then(() => {
      this.setState({ loading: true })
    });
  };

  render() {
    return (
      <Container py="2em">
        <Button onClick={this.login} disabled={this.state.loading}>
          Login
        </Button>
      </Container>
    );
  }
}

LoginPage.propTypes = {
  firebase: PropTypes.shape({
    login: PropTypes.func,
  }),
};

export default selectFirebase(['auth'])(LoginPage);
