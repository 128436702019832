import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import mime from 'mime-types';
import shortid from 'shortid';
import { withFirebase } from 'react-redux-firebase';
// import toPairs from 'lodash/toPairs';

const STORAGE_PATH = 'recording';

class BindStorage extends PureComponent {
  static propTypes = {
    firebase: PropTypes.object.isRequired,
  };

  getFileRef = filename => {
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${STORAGE_PATH}/${filename}`);
  };

  handleFileUpload = file => {
    const filename = `${shortid.generate()}.${mime.extension(file.type)}`;
    const fileRef = this.getFileRef(filename);
    return fileRef.put(file);
  };

  handleStringUpload = string => {
    const test = /data:(.+);base64/i;
    const type = string.match(test)[1];
    const extension = mime.extension(type);
    if (!extension) throw new TypeError('Invalid dataURL');

    const filename = `${shortid.generate()}.mp3`;
    const fileRef = this.getFileRef(filename);
    return fileRef.putString(string, 'data_url');
  };

  afterUpload = snapshot => {
    const { firebase } = this.props;
    return snapshot.ref.getDownloadURL().then(downloadURL => {
      const metadata = {
        downloadURL,
        fullPath: snapshot.metadata.fullPath,
        name: snapshot.metadata.name,
      };
      return firebase.push(STORAGE_PATH, metadata).then(({ key }) => ({
        ...metadata,
        key,
      }));
    });
  };

  upload = file => {
    if (file) {
      if (file instanceof File) {
        return this.handleFileUpload(file).then(this.afterUpload);
      }
      return this.handleStringUpload(file).then(this.afterUpload);
    }
    return Promise.reject()
  }

  render() {
    const { SubComp, ...props } = this.props;
    const storage = {
      list: this.list,
      upload: this.upload,
      delete: this.delete,
    };
    return <SubComp {...props} storage={storage} />;
  }
}

const WithStorage = withFirebase(BindStorage);

export default SubComp => props => <WithStorage SubComp={SubComp} {...props} />;
