import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { withFirebase } from 'react-redux-firebase';

import { withFirebaseConnect } from './DataContext'

export const selectFirebaseState = state => state.get('firebase');

export const selectFirebase = keyList => {
  const selector = createSelector(selectFirebaseState, firebase =>
    pick(firebase, keyList),
  );
  return compose(
    withFirebase,
    connect(selector),
  );
};

export const selectFirebaseData = keyList => {
  const selector = createSelector(selectFirebaseState, firebase =>
    keyList.reduce((selected, key) => {
      if (isObject(key)) {
        const { path, queryParams, storeAs } = key;
        if (path) {
          if (storeAs) {
            return Object.assign(selected, {
              [storeAs]: firebase.data[storeAs],
            });
          }
          if (queryParams)
            return Object.assign(selected, { [path]: firebase.data[path] });
        }
        return selected;
      }
      return Object.assign(selected, {
        [key]: get(firebase, ['data', ...key.split('/')]),
      });
    }, {}),
  );

  return compose(
    withFirebaseConnect(keyList),
    connect(selector),
  );
};
