import React from 'react';
import PropTypes from 'prop-types';

import { selectFirebase } from '../services/firebase/selectors';

class LogoutPage extends React.PureComponent {
  componentDidMount() {
    if (this.props.auth.isLoaded) {
      this.checkAuth();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.auth.isLoaded && this.props.auth.isLoaded) {
      this.checkAuth();
    }
  }

  checkAuth = () => {
    const { auth, history, firebase } = this.props;
    if (auth.isEmpty) {
      history.push('/login');
    } else {
      setTimeout(() => {
        firebase.logout().then(() => history.push('/login'));
      });
    }
  };

  render() {
    return null;
  }
}

LogoutPage.propTypes = {
  firebase: PropTypes.shape({
    logout: PropTypes.func,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default selectFirebase(['auth'])(LogoutPage);
