import React from 'react'
import { Provider } from 'react-redux';

import ThemeProvider from './components/ThemeProvider';
import creatStore from './stores/createStore';
import { FirebaseProvider } from './services/firebase';

const store = creatStore();

export default ({ children }) => (
  <Provider store={store}>
    <FirebaseProvider dispatch={store.dispatch}>
      <ThemeProvider>{children}</ThemeProvider>
    </FirebaseProvider>
  </Provider>
)
