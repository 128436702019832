import React from 'react'
import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import DataProvider from './DataProvider'
import config from './config.json';

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
  },
}

firebase.initializeApp(config)

const FirebaseProvider = ({ children, ...props }) => (
  <ReactReduxFirebaseProvider {...props} {...rrfProps}>
    <DataProvider>{children}</DataProvider>
  </ReactReduxFirebaseProvider>
)

export {
  firebase,
  FirebaseProvider,
};
