import React from 'react'

import Container from '../components/Container'
import Text from '../components/Text'
import FullpageVerticalCenter from '../components/FullpageVerticalCenter'
import { responsive } from '../components/ThemeProvider/theme';

import ListenData from './ListenData'

const HomePage = () => {
  return (
    <FullpageVerticalCenter>
      <Container py={responsive(0, '2em')} textAlign="center">
        <Text.h2 my="2em">
          這裡充斥的大家的看法<br />
          沒有任何身分，只有真誠的聲音
        </Text.h2>
        <ListenData />
      </Container>
    </FullpageVerticalCenter>
  )
}

export default HomePage
