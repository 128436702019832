import range from 'lodash/range';

const emToPx = (em) => `${em * 16}px`;

export const breakpoints = [22, 36, 48, 62, 90, 120].map(emToPx);
export const containerWidth = [22, 36].map(emToPx);
export const responsive = (mobile, desktop) => [mobile, null, null, desktop];

const font = 'Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';

const white = '#fff';
const black = '#000';
const primary = '#82C4E2';

export default {
  colors: {
    white,
    black,
    text: black,
    primary,
    primaryHover: '#7abcd3',
  },
  breakpoints,
  containerWidth,
  font,
  headerHeight: '5em',
  responsive,
  zOrder: range(4).map((i) => 10 ** i),
};
