/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { firebaseReducer } from 'react-redux-firebase'

import api from '../services/api/reducer'


/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    api,
    firebase: firebaseReducer,
    ...injectedReducers,
  });
}
