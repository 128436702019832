import React from 'react'

import Container from '../components/Container'
import Box from '../components/Box'
import FullpageVerticalCenter from '../components/FullpageVerticalCenter'
import Text from '../components/Text'
import Link from '../components/Link'
import Button from '../components/Button'
import Image from '../components/Image';
import { responsive } from '../components/ThemeProvider/theme';

import intro from './intro.svg'

const Section = ({ text, to, ...props }) => (
  <Box mx="auto" my="2em" textAlign="center" {...props}>
    <Button width="11em" fontSize="1.125em" is={Link} to={to}>{text}</Button>
  </Box>
)

const HomePage = () => {
  return (
    <FullpageVerticalCenter pt="2em">
      <Container textAlign="left" py={responsive(0, '2em')}>
        <Box mx="auto">
          <Image src={intro} />
        </Box>

        <Text.h4 mt="2em" mb="1em">
          歡迎來到想法留聲機<br />與大家一起交換對台灣的想法
        </Text.h4>
        <Text.h4 mb="4em">
          從你的觀察來看，台灣有哪些地方<br />正在變好、哪些地方正在變壞呢？
        </Text.h4>
        <Section text="說出我的想法" to="rec" />
        <Section text="聽聽他人的看法" to="listen" />
      </Container>
    </FullpageVerticalCenter>
  )
}

export default HomePage
