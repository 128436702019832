import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import inRange from 'lodash/inRange';
import clamp from 'lodash/clamp';
import styled, { keyframes, css } from 'styled-components';
import { timer } from 'd3-timer';

import Box from './Box';
import Flex from './Flex';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const spinDuration = 2;

const animation = ({ loading }) => loading && css`
  animation: ${rotate360} ${spinDuration}s ease-in-out infinite;
`

const Spinning = styled.svg`
  ${animation}
  width: 100%;
  height: auto;
`;

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians)),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
  ].join(' ');
}

const base = 50;
const blur = base / 10;
const size = (base * 2) + blur;
const center = base + (blur / 2);

export default class Donut extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    strokeWidth: PropTypes.number,
    value: PropTypes.number,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    strokeWidth: 2,
    value: 0,
    color: 'primary',
  }

  constructor(props) {
    super(props);
    this.offset = this.getOffset(props.value);
    this.max = props.value * 360;
    this.R = base - props.strokeWidth;
    this.C = 2 * Math.PI * this.R;
    this.fullRing = this.getOffset(1);

    this.arcLength = 12.5;
    this.state = {
      arcPath: this.getArcPath(0),
      value: props.value,
    };
    if (props.loading) {
      this.timer = timer(this.handleTick);
    }
  }

  componentWillReceiveProps({ value }) {
    if (value !== this.props.value) {
      this.offset = this.getOffset(value);
      this.max = value * 360;
    }
  }

  componentWillUnmount() {
    if (this.timer) this.timer.stop();
  }

  getOffset = (value = this.props.value) => this.C - (value * this.C)

  getArcPath = (rotate = this.rotate) => {
    if (!inRange(rotate, 0, this.max + this.arcLength)) return '';
    return describeArc(center, center, this.R, clamp(rotate - this.arcLength, 0, this.max), clamp(rotate, 0, this.max));
  }

  handleTick = (elapsed) => {
    const offset = (elapsed % (spinDuration * 1000)) / 2;
    const value = offset / 360;
    this.offset = this.getOffset(value);
    this.max = value * 360;
    this.setState({ value });
  }

  render() {
    const { strokeWidth, value, children, loading, ...props } = this.props;
    const { R, C } = this;
    return (
      <Box position="relative" pb="100%" {...props}>
        <Box
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          lineHeight="1"
        >
          <Spinning loading={loading} viewBox={`0 0 ${size} ${size}`}>
            <circle
              cx={center}
              cy={center}
              r={R}
              fill="none"
              stroke="currentColor"
              strokeWidth={strokeWidth}
              strokeDasharray={C}
              strokeDashoffset={this.offset}
              transform={`rotate(-90 ${center} ${center})`}
            />
            <path
              ref={(ref) => { this.highlight = ref; }}
              fill="none"
              stroke="currentColor"
              strokeWidth={strokeWidth}
              d={this.getArcPath()}
            />
          </Spinning>
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
        >
          <Flex height="100%" justifyContent="center" alignItems="center">
            {children}
          </Flex>
        </Box>
      </Box>
    );
  }
}
