import React, { PureComponent } from 'react';
import ReactWaves from '@dschoon/react-waves';

import Flex from '../components/Flex'
import Text from '../components/Text'
import Button from '../components/Button'

const formatTime = sec => {
  const min = Math.floor(sec / 60)
  const resSec = Math.round(sec % 60);
  if (isNaN(sec)) return ''
  return [min, resSec].map(n => String(n).padStart(2, '0')).join('m').concat('s')
}

class WavePlayer extends PureComponent {
  state = {}

  onWaveformReady = ({ wavesurfer }) => this.setState({ duration: wavesurfer.getDuration() })

  render() {
    const { audioFile, playing, disabled, onTogglePlay } = this.props
    const { duration } = this.state

    return (
      <Flex alignItems="center" px="1em">
        <Button onClick={onTogglePlay} disabled={disabled}>
          { playing ? '■' : '▶' }
        </Button>
        <ReactWaves
          audioFile={audioFile}
          options={{
            barHeight: 2,
            cursorWidth: 0,
            height: 150,
            hideScrollbar: true,
            progressColor: '#EC407A',
            responsive: true,
            waveColor: '#D1D6DA',
          }}
          volume={1}
          zoom={1}
          playing={playing}
          onPosChange={this.onPosChange}
          onReady={this.onWaveformReady}
        />
        <Text>{formatTime(duration)}</Text>
      </Flex>
    );
  }
}

WavePlayer.propTypes = {

};

export default WavePlayer;
