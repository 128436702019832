import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactHowler from 'react-howler';
import { timer } from 'd3-timer';
import { MdPause, MdPlayArrow } from 'react-icons/md';

import Donut from './Donut';
// import Button from './Button';
import Box from './Box';

class MuicPlayer extends PureComponent {
  state = {
    loaded: false,
    playing: this.props.autoplay || false,
    progress: 0,
  }

  // componentDidMount() {
  //   const audio = new Audio()
  //   audio.oncanplay = this.handleOnload
  //   audio.src = this.props.src
  // }

  componentWillUnmount() {
    if (this.onProgress) this.onProgress.stop();
  }

  startPlay = () => {
    this.setState({ playing: true }, this.handleOnPlay)
  }

  stopPlay = () => {
    this.setState({ playing: false })
  }

  handleOnPlay = () => {
    // this.duration = this.player.duration();
    if (this.onProgress) {
      this.onProgress.restart(this.handleOnProgrss);
    } else {
      this.onProgress = timer(this.handleOnProgrss);
    }
  }

  handleOnload = () => {
    this.duration = this.player.duration();
    this.setState({ loaded: true })
  }

  handleOnPause = () => {
    if (this.onProgress) this.onProgress.stop();
  }

  handleOnEnd = () => {
    this.stopPlay()
  }

  handleOnProgrss = () => {
    this.setState({ progress: this.player.seek() / this.duration })
  }

  render() {
    const { src, disabled } = this.props;
    const { loaded, progress, playing } = this.state;

    const donut = loaded ? (
      <Donut value={progress || 1}>
        <MdPause />
      </Donut>
    ) : <Donut key={src} loading />;
    return (
      <Box
        fontSize="5em"
        width="2em"
        mx="auto"
        onClick={!disabled && (playing ? this.stopPlay : this.startPlay)}
      >
        {playing ? donut : (
          <Donut value={progress || 1}>
            <MdPlayArrow />
          </Donut>
        )}
        <ReactHowler
          ref={(ref) => { this.player = ref; }}
          src={src}
          preload={false}
          playing={playing}
          onPlay={this.handleOnPlay}
          onPause={this.handleOnPause}
          onEnd={this.handleOnEnd}
          onLoad={this.handleOnload}
        />
      </Box>
    );
  }
}

MuicPlayer.propTypes = {
  src: PropTypes.string,
  onPlay: PropTypes.func,
  onStop: PropTypes.func,
  onProgress: PropTypes.func,
  progress: PropTypes.number,
};

export default MuicPlayer;
