import React, { PureComponent } from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import map from 'lodash/map'
import { withFirebase } from 'react-redux-firebase'
import { compose } from 'redux';

import Box from '../components/Box'
import { responsive } from '../components/ThemeProvider/theme';
import { selectFirebaseData } from '../services/firebase/selectors';
import WavePlayer from './WavePlayer';


class AdminPage extends PureComponent {
  state = {
    wavesurfer: null,
    playing: [],
    pos: 0,
  }

  handlePlay = (index) => {
    const { playing } = this.state
    if (!Boolean(playing[index])) {
      playing.fill(false)
    }
    playing[index] = !playing[index]
    this.setState({ playing: Array.from(playing) })
  }

  render() {
    const { recording, firebase } = this.props
    const { playing } = this.state
    const data = map(recording, (data, key) => ({
      key,
      number: data.id,
      link: data.downloadURL,
      disabled: data.disabled,
    }))
    const columns = [{
      Header: 'ID',
      accessor: 'number', // String-based value accessors!
      style: { margin: 'auto 0' },
      Cell: props => <Box textAlign="center">{props.value}</Box>
    }, {
      Header: 'Voice',
      accessor: 'link',
      Cell: ({ value, index, original }) => (
        <WavePlayer
          key={index}
          audioFile={value}
          playing={playing[index]}
          onTogglePlay={() => this.handlePlay(index)}
          disabled={original.disabled}
        />
      ) // Custom cell components!
      }, {
        Header: 'Disabled',
        accessor: 'disabled',
        Cell: ({ value, original }) => (
          <input
            key={original.key}
            type="checkbox"
            checked={value}
            onChange={() => firebase.set(`/recording/${original.key}/disabled`, !value)}
          />
        ),
      }]
    return (
      <Box textAlign="left" px="2em" py={responsive(0, '2em')}>
        <ReactTable
          data={data}
          columns={columns}
        />
      </Box>
    )
  }
}

export default compose(
  selectFirebaseData(['recording']),
  withFirebase,
)(AdminPage)
