import React, { PureComponent, createElement } from 'react';

import withData from '../services/api/withData'

import Player from '../components/Player'
import Button from '../components/Button'

class ListenData extends PureComponent {
  state = {

  }

  onNext = () => {
    const { listen, reload } = this.props
    this.setState({ autoplay: true }, () => reload({ not: listen.id }))
  }

  render() {
    const { listen } = this.props
    const { autoplay } = this.state
    return (
      <div>
        <Player key={listen.id} autoplay={autoplay} src={listen.downloadURL} />
        <Button.outline
          my="2em"
          onClick={this.onNext}
        >
          下一個
        </Button.outline>
      </div>
    );
  }
}

export default ({ id, not, ...props }) => createElement(withData('listen', not ? { not } : null)(ListenData), props);
