import React, { createElement } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import HomePage from './containers/HomePage'
import RecordPage from './containers/RecordPage'
import PlayPage from './containers/PlayPage'
import AdminPage from './containers/AdminPage'
import LoginPage from './containers/LoginPage'
import LogoutPage from './containers/LogoutPage'
import Layout from './containers/Layout'

import { UserIsAuthenticated, UserIsNotAuthenticated } from './services/firebase/authHelper'

window.TW100_REC_LOCK = window.location.search.includes('lock')

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/rec">
            <RecordPage />
          </Route>
          <Route path="/listen">
            <PlayPage />
          </Route>
          <Route path="/admin">
            {createElement(UserIsAuthenticated(AdminPage))}
          </Route>
          <Route path="/login">
            {createElement(UserIsNotAuthenticated(LoginPage))}
          </Route>
          <Route path="/logout">
            {createElement(UserIsAuthenticated(LogoutPage))}
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
