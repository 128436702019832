import React, { PureComponent, Fragment } from 'react'
import { MdStop } from 'react-icons/md';
import { ReactMic } from '@cleandersonlobo/react-mic';
import styled, { keyframes } from 'styled-components'
import Bowser from 'bowser';

import Container from '../components/Container'
import Box from '../components/Box'
import Link from '../components/Link'
import Text from '../components/Text'
import Button from '../components/Button'
import Player from '../components/Player'
import FullpageVerticalCenter from '../components/FullpageVerticalCenter'
import { responsive } from '../components/ThemeProvider/theme';

import withStorage from '../services/firebase/withStorage'

const StyledMic = styled(ReactMic)`
  max-width: 32em;
  ${props => props.disabled ? 'display:none;' : 'margin-top: 2em'}
`
const browser = Bowser.getParser(window.navigator.userAgent);
const isDesktop = browser.getPlatformType() === 'desktop';

const flash = keyframes`
10%, 90% {
  opacity: 1;
}

50% {
  opacity: 0;
}
`

const Rec = styled(Text.h2)`
  animation: ${flash} 2s ease-in-out Infinite;
`

class RecPage extends PureComponent {
  state = {
    playing: false,
  }

  componentWillUnmount() {
    this.clearTicker()
  }

  componentDidUpdate(prevProps, prevState) {
    const { recording, canRecord } = this.state
    if (!recording && prevState.recording && !canRecord) {
      this.setState({ userBlocked: true })
    }
  }

  restart = () => {
    this.setState({
      recording: false,
      recStarted: false,
      recCompleted: false,
      recordedBlob: null,
      recordedBase64: null,
      canRecord: false,
      uploaded: false,
      time: 60,
    })
  }

  startRecord = () => {
    this.setState({
      recording: true,
      recStarted: true,
      recCompleted: false,
      recordedBlob: null,
      recordedBase64: null,
      canRecord: false,
      time: 60,
    })
  }

  stopRecord = () => {
    this.clearTicker()
    this.setState({
      recording: false,
      recCompleted: true,
    })
  }

  startReplay = () => {
    this.setState({ playing: true })
  }

  stopReplay = () => {
    this.setState({ playing: false })
  }

  clearTicker = () => {
    if (this.ticker) {
      clearTimeout(this.ticker)
    }
  }

  tick = () => {
    this.setState(({ time }) => {
      if (time === 0) {
        delete this.ticker
        this.stopRecord()
      } else {
        this.setState({ time: time - 1 })
        this.ticker = setTimeout(this.tick, 1000)
      }
    })
  }

  handleStop = recorded => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ recordedBase64: e.target.result })
    };
    reader.readAsDataURL(recorded.blob);
  }

  handleUpload = () => {
    const { recordedBase64 } = this.state
    const { storage: { upload } } = this.props
    this.setState({ uploading: true })

    upload(recordedBase64).then(() => {
      this.setState({ uploading: false, uploaded: true })
    })
  }

  handleDoStarted = () => {
    const { canRecord } = this.state
    if (!canRecord) {
      this.ticker = setTimeout(this.tick, 1000)
      this.setState({ canRecord: true })
    }
  }

  render() {
    const {
      recording,
      recStarted,
      time,
      recCompleted,
      recordedBase64,
      uploading,
      uploaded,
      canRecord,
      userBlocked,
    } = this.state
    const canReplay = recCompleted && recordedBase64
    const content = (
      <Fragment>
        {uploaded ? (
            <Fragment>
              <Text.h2 my="2em">
                你的想法已經被上傳到雲端<br />
                {window.TW100_REC_LOCK ? (
                  <Fragment>
                    掃描展場外的 QR CODE<br />
                    聽聽別人的想法吧
                  </Fragment>
                ) : '去聽聽看其他人的想法吧'}
              </Text.h2>
              <Button
                fontSize="1.25em"
                is={window.TW100_REC_LOCK ? 'button' : Link}
                to="/"
                onClick={window.TW100_REC_LOCK ? this.restart : () => {}}
              >完成</Button>
            </Fragment>
          ) : (
            <Fragment>
              {!recStarted && (
                <Box>
                  <Text.h4 my="1em">
                    這20年來<br />
                    你覺得台灣正在變好還是變壞?<br />
                    為什麼呢?<br />
                  </Text.h4>
                  <Text.h4 my="1em">
                    想好你的答案，按下錄音鍵
                  </Text.h4>
                  <Text.h4 my="1em">
                    接下來會有一分鐘的時間<br />
                    可以說出你的看法
                  </Text.h4>
                  <Text.h4 my="1em">
                    準備好就按下開始
                  </Text.h4>
                </Box>
              )}
              {recording && (
                canRecord ? (
                  <Fragment>
                    {!isDesktop && <Rec color="primary">●REC</Rec>}
                    <Text fontSize="1.5em" my="1em">00:{String(time).padStart(2, '0')}</Text>
                    <Text color="gray">
                      如果已經說完想說的話<br/ >
                      也可以按下停止鍵結束錄音
                    </Text>
                  </Fragment>
                ) : <Text color="gray">初始中...</Text>
              )}
              <StyledMic
                record={recording}
                onStop={this.handleStop}
                onData={this.handleDoStarted}
                mimeType="audio/mp3"
                disabled={!isDesktop || !canRecord}
              />
              <Box my="2em">
                {canReplay ? (
                  <Player
                    src={recordedBase64}
                    disabled={uploading}
                  />
                ) : (
                  <Button.circle
                    fontSize="2em"
                    width="3em"
                    height="3em"
                    my="1em"
                    onClick={recording ? this.stopRecord : this.startRecord}
                  >
                    {recording ? <MdStop /> : '開始'}
                  </Button.circle>
                )}
              </Box>
              {canReplay && (
                <Fragment>
                  <Box my="1em">
                    <Button
                      disabled={uploading}
                      fontSize="1.25em"
                      onClick={this.handleUpload}
                    >確認上傳</Button>
                  </Box>
                  <Box my="1em">
                    <Button
                      disabled={uploading}
                      fontSize="1.25em"
                      onClick={this.restart}
                    >重新錄製</Button>
                  </Box>
                </Fragment>
              )}
            </Fragment>
          )}
      </Fragment>
    )
    return (
      <FullpageVerticalCenter>
        <Container py={responsive(0, '2em')} textAlign="center">
          {userBlocked ? (
            <Fragment>
              <Text.h3 color="gray">要允許麥克空權限<br />才可以開始錄音喔</Text.h3>
              <Button fontSize="1.25em" my="2em" onClick={() => window.location.reload()}>重新開始</Button>
            </Fragment>
          ) : content}
        </Container>
      </FullpageVerticalCenter>
    )
  }
}

export default withStorage(RecPage)
