import React, { PureComponent, createElement } from 'react'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { selectFirebaseState, selectFirebase } from './selectors';

class Redirect extends PureComponent {
  componentDidMount() {
    const { to, firebase, auth, history } = this.props
    if (auth && auth.isLoaded && !auth.isEmpty) {
      firebase.logout().then(() => history.replace(to))
    } else {
      history.replace(to);
    }
  }

  render() {
    return null
  }
}

const FirebaseRedirect = compose(selectFirebase(['auth']), withRouter)(Redirect);

const authenticatingSelector = (state) => {
  const { auth, profile, isInitializing } = selectFirebaseState(state);
  return isInitializing === true || !auth.isLoaded || !profile.isLoaded;
};

export const UserIsAuthenticated = connectedAuthWrapper({
  wrapperDisplayName: 'UserIsAuthenticated',
  authenticatingSelector,
  authenticatedSelector: (state) => {
    const { auth, profile } = selectFirebaseState(state);
    return !auth.isEmpty && profile.valid;
  },
  AuthenticatingComponent: () => null,
  FailureComponent: () => <FirebaseRedirect to="/login" />
});

export const UserIsNotAuthenticated = connectedAuthWrapper({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  authenticatingSelector,
  authenticatedSelector: (state) => {
    const { auth } = selectFirebaseState(state);
    return auth.isEmpty;
  },
  AuthenticatingComponent: () => null,
  FailureComponent: () => createElement(withRouter(Redirect), { to: '/' }),
});
