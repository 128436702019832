import { call, put, takeEvery } from 'redux-saga/effects';
import reduce from 'lodash/reduce'
import isObject from 'lodash/isObject'

import sendRequest from '../../utils/request';

import { GET_DATA, receivedDataSuccess, receivedDataError } from './reducer';

const API_BASE = 'https://us-central1-tw100-voice.cloudfunctions.net/api';

function* handleRequest(target, onSuccess, onError) {
  try {
    const res = yield call(sendRequest, target);
    yield put(onSuccess(res));
  } catch (error) {
    console.error(error)
    yield put(onError(error));
  }
}

function* handleManual({ payload: { key, params } }) {
  const qs = isObject(params) ? reduce(params, (q, value, key) => `${q}${key}=${encodeURIComponent(value)}&`, '?') : ''
  const resourceBase = `${API_BASE}/${key}${qs}`;
  yield call(
    handleRequest,
    resourceBase,
    (data) => receivedDataSuccess({
      key,
      data,
    }),
    // onError
    () => receivedDataError({ key }),
  );
}

export default function* apiSagas() {
  yield takeEvery(GET_DATA, handleManual);
}
