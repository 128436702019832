import React, { PureComponent } from 'react'
import Link from '../components/Link';
import Box from '../components/Box';
import Flex from '../components/Flex';
import Text from '../components/Text';

const t = (
  <Text.h1 color="white" fontWeight="900">
    想法留聲機
  </Text.h1>
)

class Header extends PureComponent {
  state = {}

  keys = {}

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = (e) => {
    this.keys[e.keyCode] = true
    setTimeout(this.checkKeys)
  }

  handleKeyUp = (e) => {
    this.keys[e.keyCode] = false
    setTimeout(this.checkKeys)
  }

  checkKeys = () => {
    this.setState({ magicAdmin: [17, 18].every((k) => this.keys[k]) })
  }

  render() {
    return (
      <Flex
        position="fixed"
        bg="primary"
        top={0}
        left={0}
        right={0}
        alignItems="center"
        zOrder={2}
        {...this.props}
      >
        <Box px="1em" flex={1}>
          {window.TW100_REC_LOCK ? t : <Link to={this.state.magicAdmin ? '/admin' : '/'}>{t}</Link>}
        </Box>
      </Flex>
    )
  }
}

export default Header
